
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `ホーム画面に追加して、幅広い体験と簡単なアクセスを実現してください。`,
's1b9047d53d9f9d22': `2) ナビゲーションバーで共有を押す`,
's37a9e8aec5713460': `展開`,
's5c6aad7a7e4a1437': `3) 「ホーム画面に追加」を押す`,
's6196153c4b0c1ea0': `インストール`,
's922329d6f6213590': `Dockに追加`,
'sa5ef80b4bb9b39f8': `折りたたむ`,
'sa7551383d1897fcc': `2) 「Dockに追加」を押す`,
'sba52286c21552a4e': `デバイスにインストールすることで、より充実した体験と簡単なアクセスを実現できます。`,
'sc79fd7641eb9a975': `説明を非表示`,
'scdaf4bbff76674c8': `ホーム画面に追加`,
'sdfe23506a3b0cdc8': `1) Safariで開く`,
'se740f75b95a51807': `ドックに追加して、幅広い体験と簡単なアクセスを実現してください。`,
'sfea652f6580ff086': `このサイトはアプリ機能があります。`,
    };
  