
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `Προσθέστε το στην οθόνη Αφετηρίας για εκτεταμένη εμπειρία και εύκολη πρόσβαση.`,
's1b9047d53d9f9d22': `2) Πατήστε Κοινοποίηση στη γραμμή πλοήγησης`,
's37a9e8aec5713460': `Περισσότερα`,
's5c6aad7a7e4a1437': `3) Πατήστε Προσθήκη στην οθόνη Αφετηρίας`,
's6196153c4b0c1ea0': `Εγκατάσταση`,
's922329d6f6213590': `Προσθήκη στην Πρόσβαση`,
'sa5ef80b4bb9b39f8': `Λιγότερα`,
'sa7551383d1897fcc': `2) Πατήστε Προσθήκη στην Πρόσβαση`,
'sba52286c21552a4e': `Εγκαταστήστε το στη συσκευή σας για εκτεταμένη εμπειρία και εύκολη πρόσβαση.`,
'sc79fd7641eb9a975': `Απόκρυψη Οδηγιών`,
'scdaf4bbff76674c8': `Προσθήκη στην Οθόνη Αφετηρίας`,
'sdfe23506a3b0cdc8': `1) Ανοίξτε τον περιηγητή Safari`,
'se740f75b95a51807': `Προσθέστε το στην Πρόσβαση για εκτεταμένη εμπειρία και εύκολη πρόσβαση.`,
'sfea652f6580ff086': `Αυτός ο ιστότοπος έχει λειτουργική εφαρμογής.`,
    };
  