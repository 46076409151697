
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `Adicione à Tela de Início para uma melhor experiência e mais fácil acesso.`,
's1b9047d53d9f9d22': `2) Pressione Compartilhar na barra de endereço`,
's37a9e8aec5713460': `Mais`,
's5c6aad7a7e4a1437': `3) Pressione Adicionar à Tela de Início`,
's6196153c4b0c1ea0': `Instalar`,
's922329d6f6213590': `Adicionar ao Dock`,
'sa5ef80b4bb9b39f8': `Menos`,
'sa7551383d1897fcc': `2) Pressione Adicionar ao Dock`,
'sba52286c21552a4e': `Instale no dispositivo para uma melhor experiência e mais fácil acesso.`,
'sc79fd7641eb9a975': `Ocultar Instruções`,
'scdaf4bbff76674c8': `Adicionar à Tela de Início`,
'sdfe23506a3b0cdc8': `1) Abra no navegador Safari`,
'se740f75b95a51807': `Adicione ao Dock para uma melhor experiência e mais fácil acesso.`,
'sfea652f6580ff086': `Este site possui funcionalidade de aplicativo.`,
    };
  