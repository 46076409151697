
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `Додајте га на Home Screen за широке могућности и лак приступ.`,
's1b9047d53d9f9d22': `2) Притисните Share у навигационој траци`,
's37a9e8aec5713460': `Више`,
's5c6aad7a7e4a1437': `3) Притисните Add to Home Screen`,
's6196153c4b0c1ea0': `Инсталирај`,
'sa5ef80b4bb9b39f8': `Мање`,
'sa7551383d1897fcc': `3) Притисните Add to Dock`,
'sba52286c21552a4e': `Инсталирајте га на вашем уређају за широке могућности и лак приступ.`,
'sc79fd7641eb9a975': `Сакриј упутство`,
'sdfe23506a3b0cdc8': `1) Отворите у Safari прегледачу`,
'se740f75b95a51807': `Додајте га у вашу Dock за широке могућности и лак приступ.`,
'sfea652f6580ff086': `Овај сајт има функционалност апликације.`,
's922329d6f6213590': `Add to Dock`,
'scdaf4bbff76674c8': `Add to Home Screen`,
    };
  