
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `Añádelo a tu pantalla de inicio para una experiencia amplia y acceso fácil.`,
's1b9047d53d9f9d22': `2) Presionar Compartir en la barra de direcciones`,
's37a9e8aec5713460': `Más`,
's5c6aad7a7e4a1437': `3) Presionar Añadir a pantalla de inicio`,
's6196153c4b0c1ea0': `Instalar`,
's922329d6f6213590': `Añadir al Dock`,
'sa5ef80b4bb9b39f8': `Menos`,
'sa7551383d1897fcc': `2) Presionar Añadir al Dock`,
'sba52286c21552a4e': `Instálalo en tu dispositivo para una experiencia amplia y acceso fácil.`,
'sc79fd7641eb9a975': `Esconder instrucciones`,
'scdaf4bbff76674c8': `Añadir a pantalla de inicio`,
'sdfe23506a3b0cdc8': `1) Abrir en el navegador web Safari`,
'se740f75b95a51807': `Añádelo a tu Dock para una experiencia amplia y acceso fácil.`,
'sfea652f6580ff086': `Este sitio tiene funcionalidad de aplicación.`,
    };
  